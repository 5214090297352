import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import westLogo from "assets/images/fitfarm-omaha-west-location-logo.svg"
import midLogo from "assets/images/fitfarm-omaha-midtown-location-logo.svg"
const HomeOmahaGyms = (props) => {
  console.log("Props ====> BG", props)
  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={props.sectionImage.childImageSharp.fluid}
        className="banner-location"
      >
        <div className="u-center-text" style={{ paddingTop: "5rem" }}>
          <h2 className="type-header__locations type-emph type-white">
            OMAHA <span className="type-heavy">Gyms</span>
          </h2>
        </div>
        <div className="grid-location u-mt-2">
          <div className="grid-location__col">
            <img
              src={westLogo}
              alt="Fitfarm west logo"
              className="img-location"
            />
            <p className="type-reg type-white type-body u-mt-2">
              <span className="type-heavy type-white">Gym: </span>
              FitFarm West
            </p>
            <p className="type-reg type-yellow type-body">
              <span className="type-heavy type-white">Address: </span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/search?tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALeKk02JeqKtDyT1y6oWn20epRHrrOWICQ:1617824612074&q=fitfarm&rflfq=1&num=10&ved=2ahUKEwikwL6q8uzvAhXILs0KHetOClwQtgN6BAgIEAc#rlfi=hd:;si:10939549952394554127,l,CgdmaXRmYXJtSOGgi4nQrYCACFoWCgdmaXRmYXJtEAAYACIHZml0ZmFybZIBA2d5bQ;"
              >
                13748 F St Suite 100, Omaha, NE 68137
              </a>
            </p>
            <p className="type-reg type-white type-body">
              <span className="type-heavy type-white">Amenities: </span>
              Our FitFarm West gym is located in Millard and has easy interstate
              access. The 5,550 sqft gym has a full locker rooms with showers
              for men and women. We have outfitted the gym with all Rogue
              equipment, which we keep clean and well maintained. The gym has
              dedicated areas for Personal Training, Powerlifting, and Strongman
              Training. There is also an area for outdoor workouts and running.
            </p>
            <p className="type-heavy type-white type-body">
              Hours of Operation:{" "}
            </p>
            <ul>
              <li className="type-white type-body type-reg">
                Monday through Friday, 5:00am to 8:00pm
              </li>
              <li className="type-white type-body type-reg">
                Saturday and Sunday, 8:00am to 12:00pm
              </li>
            </ul>
          </div>
          <div className="grid-location__col ">
            <img
              src={midLogo}
              alt="Fitfarm midtown logo"
              className="img-location"
            />
            <p className="type-reg type-white type-body u-mt-2">
              <span className="type-heavy type-white">Gym: </span>
              FitFarm Midtown
            </p>
            <p className="type-reg type-yellow type-body">
              <span className="type-heavy type-white">Address: </span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/search?tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALeKk02JeqKtDyT1y6oWn20epRHrrOWICQ:1617824612074&q=fitfarm&rflfq=1&num=10&ved=2ahUKEwikwL6q8uzvAhXILs0KHetOClwQtgN6BAgIEAc#rlfi=hd:;si:16853681004473212469,l,CgdmaXRmYXJtSOCgvY6TgoCACFoWCgdmaXRmYXJtEAAYACIHZml0ZmFybZIBA2d5bQ"
              >
                4383 Nicholas St, Omaha, NE 68131
              </a>
            </p>
            <p className="type-reg type-white type-body">
              <span className="type-heavy type-white">Amenities: </span>
              This gym is located in the historical Omar Baking Building. A
              beautiful building with a brick exterior. The 10,000 sq. foot
              facility has full locker rooms with showers, indoor turf, and an
              Olympic Lifting floor with Rogue equipment. The gym has dedicated
              areas for CrossFit and Personal Training. Some additional features
              include aerial yoga, conditioning, and strength equipment.
            </p>
            <p className="type-heavy type-white type-body">
              Hours of Operation:{" "}
            </p>
            <ul>
              <li className="type-white type-body type-reg">
                Monday through Friday, 5:00am to 8:00pm
              </li>
              <li className="type-white type-body type-reg">
                Saturday, 8:00am to 12:00pm
              </li>
            </ul>
          </div>
        </div>
        <div
          className="u-center-text"
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        >
          <Link
            to="/contact-us/"
            className="btn btn-primary btn-primary__gold type-heavy type-black"
          >
            Workout with us
          </Link>
        </div>
      </BackgroundImage>
    </>
  )
}

export default HomeOmahaGyms
