import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "components/Layout"
import HomeBanner from "components/Banners/HomeBanner"
import HomeGrowingFitness from "components/HomeGrowingFitness"
import HomeCrossfitClasses from "components/HomeCrossfitClasses"
import HomeNutrition from "components/HomeNutrition"
import HomePersonalTraining from "components/HomePersonalTraining"
import HomeOmahaGyms from "components/HomeOmahaGyms"
import HomeCommunity from "components/HomeCommunity"
import Signup from "components/Signup"
import Seo from "components/Seo"
import seoImage from "../assets/images/fitfarm-omaha-gyms-locations-bg-1.webp"
const getImages = graphql`
  {
    bannerMain: file(
      relativePath: {
        eq: "fitfarm-omaha-crossfit-personal-training-banner-home.webp"
      }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 1288) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bannerTl: file(
      relativePath: {
        eq: "fitfarm-omaha-crossfit-personal-training-banner-home-tl.webp"
      }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bannerBr: file(
      relativePath: {
        eq: "fitfarm-omaha-crossfit-personal-training-banner-home-br.webp"
      }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    growingFitness: file(
      relativePath: { eq: "fitfarm-omaha-growing-fitness-home.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    crossfit: file(
      relativePath: { eq: "fitfarm-omaha-crossfit-classes-banner-home.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nutrition: file(relativePath: { eq: "fitfarm-nutrition-banner.webp" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    personalTraining: file(
      relativePath: { eq: "fitfarm-omaha-gym-personal-training.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    omahaGyms: file(
      relativePath: { eq: "fitfarm-omaha-gyms-locations-bg-1.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    community: file(
      relativePath: { eq: "fitfarm-omaha-gyms-community-bg.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    signup: file(
      relativePath: { eq: "fitfarm-midtown-gym-omaha-interior.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const IndexPage = () => {
  const imgData = useStaticQuery(getImages)

  return (
    <Layout>
      <Seo
        title="Grow your Fitness: Omaha's Premier Gym FitFarm"
        description="FitFarm is Omaha's premier gym for everything fitness. We have daily CrossFit classes, personal training sessions, and nutrition consults."
        keywords={["omaha gym", "omaha gyms", "gym omaha", "gyms omaha"]}
        pathname={"/"}
        image={seoImage}
      />
      <article>
        <HomeBanner
          leftImage={imgData.bannerTl}
          centerImage={imgData.bannerMain}
          rightImage={imgData.bannerBr}
        />
        <HomeGrowingFitness sectionImage={imgData.growingFitness} />
        <HomeCrossfitClasses sectionImage={imgData.crossfit} />
        <HomeNutrition sectionImage={imgData.nutrition} />
        <HomePersonalTraining sectionImage={imgData.personalTraining} />
        <HomeOmahaGyms review="hello" sectionImage={imgData.omahaGyms} />
        <HomeCommunity sectionImage={imgData.community} s />
        <Signup sectionImage={imgData.signup} />
      </article>
    </Layout>
  )
}

export default IndexPage
